// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&family=Redacted+Script:wght@700&family=Rubik+Mono+One&display=swap');

// Bootstrap Icons
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");

// FontAwesome Icons
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css");

// Font Variables
$quicksand: 'Quicksand', sans-serif;
$rubik_mono: 'Rubik Mono One', Impact, sans-serif;
$redacted_script: 'Redacted Script', sans-serif;

// Cards
$card-border-radius: 1.2rem;

// Shadows
$box-shadow:  0 .5rem 1rem rgba(#dd752e, .15);