// typography
$font-family-base: $quicksand;
$font-size-base: 1rem;
$body-color: #007bb6;

.display-1 {
	font-size: 2.2rem !important ;
}
$h1-font-size: $font-size-base * 1.3;
$h2-font-size: $font-size-base * 0.9;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 0.7;

$display-font-family: $quicksand;
$headings-font-family: $quicksand;
$h2-font-family: $rubik_mono;
$h3-font-family: $redacted_script;
$h4-font-family: $rubik_mono;
h1 {
	font-family: $headings-font-family !important;
}
h2 {
	font-family: $h2-font-family !important;
}
h3 {
	font-family: $h3-font-family !important;
	color: #dd732c !important;
	margin-top: -0.8rem !important;
}
h4 {
	font-family: $h4-font-family !important;
}

.header-app-name {
	font-size: $font-size-base * 1 !important;
}

.sm-text {
	font-size: $font-size-base * 0.8 !important;
}

//colors
$theme-colors: (
	"light": #ffffff,
	"dark": #16122c,
	"primary": #007bb6,
	"secondary": #dd752e,
	"info": #11c26d,
	"success": #00b965,
	"warning": #ebd000,
	"danger": #ff0004,
);

//cards

// header image
.header-logo {
	width: 2.7em;
}

// introduction
.full-height {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.full-height > :first-child {
	margin-top: auto;
}
.full-height > :nth-child(2) {
	align-self: center;
	margin: auto; /* Center horizontally within the container */
}

.percent-85-height {
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.percent-70-height {
	min-height: 50vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

// icons
.icon-lg {
	font-size: 1.1rem;
	padding-right: 0.2rem;
}

.icon-description {
	font-size: 2.8rem;
	color: #dd732c;
	margin-bottom: 0.5em;
}

// Buttons
.btn {
	font-size: 1rem !important;
	padding: 0.2rem 0.8rem !important;
	margin: 0.2rem !important;
	border-radius: 5rem !important;

	&:hover {
		box-shadow: 0 0 40px rgba(255, 114, 27, 0.576);
	}

	// Customize the primary button
	&-primary {
		background-color: #007bb6 !important;
		border-color: #007bb6 !important;
		&:hover {
			background-color: #dd752e !important;
			border-color: #dd752e !important;
		}
	}
}

//Carousel
button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
	background-color: #007bb6;
	box-shadow: 0 0 10px white;
	left: 0;

	&:hover {
		background-color: #dd752e;
	}
}

button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
	background-color: #007bb6;
	box-shadow: 0 0 10px white;
	right: 0.5%;

	&:hover {
		background-color: #dd752e;
	}
}

.react-multi-carousel-dot--active button {
	background: #007bb6 !important;
}

// Hero
.orange-color {
	color: #dd752e;
}

//Footer

.link-underline {
	text-decoration: none;
}

.list li {
	padding-bottom: 5px;
	font-size: 0.9rem !important;
}

.copyright {
	font-size: 0.7rem;
}

// Media queries
.small-devices-hide {
	display: none !important;
}

// X-Small devices (portrait phones, 0px and up)
@media (min-width: 0px) {
}

// Small devices (landscape phones, 320px and up)
@media (min-width: 320px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
	.display-1 {
		font-size: 3.2rem !important ;
	}
	h1 {
		font-size: $font-size-base * 1.6 !important;
	}
	.header-app-name {
		font-size: 1.5rem !important;
	}
	.small-devices-hide {
		display: inline !important;
	}
	.btn {
		font-size: 1.2rem !important;
		padding: 0.3rem 1.4rem !important;
		margin: 0.5rem !important;
	}
}

// Large devices (desktops, 1024px and up)
@media (min-width: 1024px) {
	.display-1 {
		font-size: 4rem !important ;
	}
	h1 {
		font-size: $font-size-base * 2.1 !important;
	}
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
	.display-1 {
		font-size: 4.1rem !important ;
	}
	h1 {
		font-size: $font-size-base * 2.3 !important;
	}
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1440px) {
	html {
		font-size: 1.2rem;
	}
	.display-1 {
		font-size: 4.1rem !important ;
	}
	h1 {
		font-size: $font-size-base * 2.4 !important;
	}
}

// XXX-Large devices
@media (min-width: 2400px) {
	html {
		font-size: 1.8rem !important;
	}
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
