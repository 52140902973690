
:root {
  --custom-table-bg: #ece3e33e;
}

.custom-table {
  background-color: var(--custom-table-bg) !important;
}

.custom-table tbody tr:hover {
  background-color: rgb(255, 244, 244); 
}

.header-logo-container {
  display: flex;
  align-items: center;
  justify-items:space-between;
}

.search-bar {
  margin-left: auto;
  max-width: 350px; 
  
}
.custom-table-container {
  width: 100%;
  height: 1000px; 
  overflow: auto; 
}

.custom-table {
  width: 100%;
  border-collapse: collapse; 
}

.custom-table th,
.custom-table td {
  padding: 8px;
  border: 3px solid #dd732c; 
}

.custom-table thead th {
  background-color: #dd732c;
  color: #fff;
}

.header-logo-container {
  display: flex;
  
}

.dashboard-btn {
  background-color: #007bb6;
}
